import React from "react"
import { Link } from "gatsby"
import Footer from "../components/footer"


import logoGold from "../img/gh-rough.jpg"

export default function fourohfour() {
    return (
        <div className="fourohfour">
   
            <div className="wrapper background--mid">
                <div className="row">
                    <div className="col-12">
                     <h1>Page not found</h1> 
                     <Link to="/" className="dark-link"><span className="back-arrow">→</span>Return Home</Link>
                    </div>
                </div>
            </div>
            <section className="footer background--dark" name="contact" id="contact">
                <Footer></Footer>
                <div className="container footer--bottom">
                    <div className="wrapper--outer">
                    <img className="logo-small" src={logoGold} alt="gypsy hub gold logo" width="150" height="150" />
                    </div>
                </div>
            </section>
        </div>
        );
    }